import React, { useEffect, useContext, useMemo } from "react";
import * as Styled from "./style";
import { Grid, Button, useMediaQuery, useTheme } from "@mui/material";
import Select from "react-select";
import { constants } from "../../constants/Constants";
import { useAutomation } from "../../hooks/automation-hook";
import { helperFunctions } from "../../utils/helperFunctions";
import { FilterContext } from "../FilterBar/FilterProvider";
import { useLayoutNavigation } from "../../hooks/layout-navigation-hook";
import { ThemeContext } from "../../context/ThemeContext";

export default function AutomationFilters() {
  const { selectCustomStyles } = useLayoutNavigation();
  const theme = useTheme();
  const { mode } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const basicOption = useMemo(() => ({ value: "All", label: "All" }), []);
  const {
    selectedTool,
    setSelectedTool,
    selectedProject,
    setSelectedProject,
    toolAllOptions,
    projectAllOptions,
  } = useContext(FilterContext);

  const {
    automationFiltersData,
    getAutomationFiltersData,
    automationFiltersLoading,
  } = useAutomation();

  const { getToolMapProject } = helperFunctions();

  // Memoize the tool and project options to avoid recalculating unnecessarily
  const [toolOptions, projectOptions, toolProjectMap] = useMemo(() => {
    if (!automationFiltersLoading && automationFiltersData.length) {
      const { tools, projects, toolProjectMapping } = getToolMapProject(
        automationFiltersData
      );
      return [tools, projects, toolProjectMapping];
    }
    return [[basicOption], [basicOption], new Map()];
  }, [
    automationFiltersData,
    automationFiltersLoading,
    getToolMapProject,
    basicOption,
  ]);

  useEffect(() => {
    getAutomationFiltersData({ variables: {} });
  }, [getAutomationFiltersData]);

  useEffect(() => {
    if (
      !automationFiltersLoading &&
      toolOptions.length > 0 &&
      projectOptions.length > 0
    ) {
      if (!selectedTool) {
        setSelectedTool(toolOptions[0]); // Set the first tool as default
      }
      if (!selectedProject) {
        setSelectedProject(projectOptions[0]); // Set the first project as default
      }
    }
  }, [
    automationFiltersLoading,
    toolOptions,
    projectOptions,
    selectedTool,
    selectedProject,
    setSelectedTool,
    setSelectedProject,
  ]);

  // Handle tool filter change and update project options
  const onToolFilterChange = (selectedOption) => {
    setSelectedTool(selectedOption);
    const availableProjectsOptions = toolProjectMap.get(
      selectedOption.value
    ) || [basicOption];
    setSelectedProject(availableProjectsOptions[0]);
  };

  // Clear all filters
  const clearAllFilters = () => {
    setSelectedTool(toolAllOptions[0] || basicOption);
    setSelectedProject(projectAllOptions[0] || basicOption);
  };

  return (
    <Styled.AutomationFiltersContainer
      data-testid="automation-filters-container"
      mode={mode}
    >
      {isMobile ? (
        <Grid container xs={12} justifyContent="flex-start">
          <Grid item container xs={12}>
            <Grid item xs={12} className="automationFilterText">
              {constants?.automation?.tools}
            </Grid>
            <Grid item xs={4}>
              <Select
                options={toolOptions}
                isSearchable={false}
                placeholder={"All"}
                styles={selectCustomStyles}
                value={selectedTool}
                data-testid="automation-tools"
                onChange={onToolFilterChange}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={2.5}
            md={2}
            lg={1.5}
            xl={1}
            className="mobileProjectFilter"
          >
            <Grid item xs={12} className="automationFilterText">
              {constants?.automation?.projects}
            </Grid>
            <Grid item xs={4}>
              <Select
                options={projectOptions}
                isSearchable={false}
                placeholder={"All"}
                styles={selectCustomStyles}
                value={selectedProject}
                data-testid="automation-projects"
                onChange={setSelectedProject}
                menuPlacement="top"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container xs={12} justifyContent="flex-end" alignItems="center">
          <Grid item container xs={12} sm={2.5} md={2} lg={1.5} xl={1}>
            <Grid item xs={12} className="automationFilterText">
              {constants?.automation?.tools}
            </Grid>
            <Grid item xs={12}>
              <Select
                options={toolOptions}
                isSearchable={false}
                placeholder={"All"}
                styles={selectCustomStyles}
                value={selectedTool}
                data-testid="automation-tools"
                onChange={onToolFilterChange}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={2.5}
            md={2}
            lg={1.5}
            xl={1}
            className="projectFilter"
          >
            <Grid item xs={12} className="automationFilterText">
              {constants?.automation?.projects}
            </Grid>
            <Grid item xs={12}>
              <Select
                options={projectOptions}
                isSearchable={false}
                placeholder={"All"}
                styles={selectCustomStyles}
                value={selectedProject}
                data-testid="automation-projects"
                onChange={setSelectedProject}
                maxMenuHeight={150}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={1.7} md={1.2} lg={1} xl={0.7}>
            <Grid item xs={0} ms={12}>
              &nbsp;
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button className="clearAllButton" onClick={clearAllFilters}>
                {constants?.automation?.clearAll}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Styled.AutomationFiltersContainer>
  );
}
