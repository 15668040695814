export const colorTokens = {
  light: {
    textPrimary: "#3A3A3A",
    textSecondary: "#616161",
    icon: "#3A3A3A",
    border: "#D7E5F8",
    backgroundLayer1: "#FBFCFE",
    verticalDivider: "#DFDFDF",
    horizontalDivider: "#DFDFDF",
    infoLabelText: "#005CBC",
    heatMapMinRange: "#E6F5FA",
    heatMapMaxRange: "#33ABDA",
    heatMapLowValue: "#D40020",
    heatMapHighValue: "#FFBF1C",
    // ...other color tokens...
  },
  dark: {
    textPrimary: "#EFEFEF",
    textSecondary: "#D0D0D0",
    icon: "#EFEFEF",
    border: "#3A3A3A",
    backgroundLayer1: "#1D1D1D",
    verticalDivider: "#3A3A3A",
    horizontalDivider: "#3A3A3A",
    infoLabelText: "#3387DF",
    heatMapMinRange: "#E5F5FC",
    heatMapMaxRange: "#33B1E8",
    heatMapLowValue: "#D40020",
    heatMapHighValue: "#FFBF1C",
    // ...other color tokens...
  },
};
