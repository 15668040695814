import React from "react";
import { Stack } from "@mui/material";
import "./style.css";

const Loader = () => {
  return (
    <Stack direction="column" alignItems="center" data-testid="loader">
      <svg className="spinner-svg spinner-header" viewBox="0 0 50 50">
        <circle className="svg-spinner-background" cx="25" cy="25" r="20" />
        <circle className="svg-spinner-spin" cx="25" cy="25" r="20"></circle>
      </svg>
    </Stack>
  );
};

export default Loader;
