import React, { useState, useEffect, useRef, useContext } from "react";
import * as Styled from "./style";
import { AiOutlineClose } from "react-icons/ai";
import { MdExpandMore, MdFilterListAlt } from "react-icons/md";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Drawer,
  Button,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLayoutNavigation } from "../../hooks/layout-navigation-hook";
import { CustomDatePicker } from "./CustomCheckbox";
import useCustomMediaQuery from "../../utils/useCustomMediaQuery";
import AutomationFilters from "../AutomationFilters";
import ShipmentVisibilityFilter from "../ShipmentVisibilityFilter";
import { constants, screenSizes } from "../../constants/Constants";
import { useDSD } from "../../hooks/dsd-hook";
import { helperFunctions } from "../../utils/helperFunctions";
import "./FilterBar.css";
import CustomSwitch from "../CustomSwitch/CustomSwitch";
import { ThemeContext } from "../../context/ThemeContext";
import { color as themeColor } from "theme/Color";

const AccordionComponent = ({ title, mode, children }) => {
  return (
    <Accordion
      defaultExpanded
      className="accordionDefaultStyle"
      sx={{
        background:
          mode === "light"
            ? `${themeColor._FBFCFE} !important`
            : `${themeColor._1d1d1d} !important`,
        color:
          mode === "light" ? `${themeColor._3a3a3a}` : `${themeColor._D0D0D0}`,
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        borderBottom:
          mode === "light"
            ? `1.5px solid ${themeColor._DFDFDF}`
            : `1.5px solid ${themeColor._666666}`,
      }}
    >
      <AccordionSummary
        expandIcon={
          <MdExpandMore
            style={{
              color:
                mode === "light"
                  ? `${themeColor._3a3a3a}`
                  : `${themeColor._D0D0D0}`,
              fontSize: "24px",
            }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const GSPPFilters = ({
  title,
  options,
  selectedValue,
  defaultValue = null,
  setSelectedValue,
  setDefaultValue,
  keyItem,
  mode,
}) => {
  return (
    <AccordionComponent title={title} mode={mode}>
      <RadioGroup
        key={`market-options-${title}`}
        aria-labelledby={`demo-radio-buttons-group-label-${title}`}
        name="radio-buttons-group"
        value={selectedValue || defaultValue}
        onChange={(e) => {
          setSelectedValue(e.target.value);
          if (setDefaultValue) {
            setDefaultValue(e.target.value);
          }
        }}
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              key={option[keyItem]}
              value={option[keyItem]}
              onChange={() => setSelectedValue(option[keyItem])}
              control={
                <Radio
                  key={option[keyItem]}
                  sx={{
                    color:
                      selectedValue === option[keyItem]
                        ? themeColor._3387DF
                        : themeColor._D0D0D0,
                    "&.Mui-checked": {
                      color: themeColor._3387DF,
                    },
                  }}
                />
              }
              label={option[keyItem]}
              name={option[keyItem]}
            />
          );
        })}
      </RadioGroup>
    </AccordionComponent>
  );
};
export default function MobileFilter({
  handleCheckboxChange,
  isChecked,
  selectedYear,
  selectedDate,
  setSelectedDate,
  setSelectedYear,
  setSelectedProgram,
  ...localContext
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { mode } = useContext(ThemeContext);

  const {
    regionValues,
    selectedProgram,
    productValues,
    modelValues,
    marketValues,
    sectorValues,
    setSelectedProduct,
    selectedProduct,
    selectedSector,
    selectedMarket,
    selectedModel,
    selectedRetailer,
    selectedPPRegion,
    selectedZone,
    selectedDistrict,
    setSelectedModel,
    setSelectedSector,
    setSelectedMarket,
    setSelectedCustomer,
    setSelectedRetailer,
    setSelectedPPRegion,
    setSelectedZone,
    setSelectedDistrict,
    setSelectedStartDate,
    setStartUTCDate,
    setSelectedEndDate,
    setEndUTCDate,
    setSelectedFilters,
    setSelectedTool,
    setSelectedProject,
    toolAllOptions,
    projectAllOptions,
    selectedStore,
    setSelectedStore,
    storeOptions,
    setStoreOptions,
    selectedPlant,
    setSelectedPlant,
    plantValues,
  } = localContext;
  const isTablet = useCustomMediaQuery(screenSizes.tablet);
  const basicOption = { value: "All", label: "All" };
  const [state, setState] = useState({
    bottom: false,
  });
  const [_defaultProgramValue, setDefaultProgramValue] = useState(
    regionValues[0]?.value
  );
  const [_defaultProductValue, setDefaultProductValue] = useState(
    productValues[0]?.value
  );

  const [defaultModelValue, setDefaultModelValue] = useState(
    modelValues.length > 0 ? modelValues[0]?.label : null
  );

  const [defaultSectorValue, setDefaultSectorValue] = useState(
    sectorValues[0]?.value
  );
  const [defaultMarketValue, setDefaultMarketValue] = useState(
    marketValues[0]?.value
  );

  const [defaultPlantValue, setDefaultPlantValue] = useState(
    plantValues[0]?.value
  );
  const [defaultCustomerValue, setDefaultCustomerValue] = useState(null);
  const [selectedProgramValue, setSelectedProgramValue] = useState(
    regionValues[0]?.value
  );
  const [selectedProductValue, setSelectedProductValue] = useState(
    productValues[0]?.value
  );

  const [selectedSectorValue, setSelectedSectorValue] = useState(
    selectedSector?.sectorName || defaultSectorValue
  );
  const [selectedMarketValue, setSelectedMarketValue] = useState(
    selectedMarket?.marketName || defaultMarketValue
  );

  const [selectedPlantValue, setSelectedPlantValue] = useState(
    selectedPlant || defaultPlantValue
  );

  const [selectedCustomerValue, setSelectedCustomerValue] =
    useState(defaultCustomerValue);
  const [selectedModelValue, setSelectedModelValue] = useState(
    selectedModel?.modelName || defaultModelValue
  );
  const [productOptions, setProductOptions] = useState(productValues);
  const [sectorOptions, setSectorOptions] = useState(sectorValues);
  const [marketOptions, setMarketOptions] = useState(marketValues);
  const [plantOptions, setPlantOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [displayModel, setDisplayModel] = useState(false);
  const [modelMarket, setModelMarket] = useState({});
  const [modelOptions, setModelOptions] = useState([]);
  const [retailerOptions, setRetailerOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [selectedRetailerValue, setSelectedRetailerValue] = useState(
    selectedRetailer?.value
  );

  const [selectedRegionValue, setSelectedRegionValue] = useState(
    selectedPPRegion?.value
  );
  const [selectedZoneValue, setSelectedZoneValue] = useState(
    selectedZone?.value
  );
  const [selectedDistrictValue, setSelectedDistrictValue] = useState(
    selectedDistrict?.value
  );
  const matchingZoneValue = useRef(selectedZoneValue);
  const matchingDistrictValue = useRef(selectedDistrictValue);

  const isInitialMountDistrict = useRef(true);
  const isApplyingFilters = useRef(false);
  const {
    getStartOfMonth,
    getEndOfMonth,
    getCurrentDayOfMonth,
    getGSPPFiltersData,
    gsppFiltersData,
  } = useLayoutNavigation();

  const { getShipmentVisibilityStoreFilterOptions } = helperFunctions();

  useEffect(() => {
    if (selectedModel) {
      getGSPPFiltersData({
        variables: { modelId: selectedModel?.id },
      });
    }
  }, [getGSPPFiltersData, selectedModel]);
  const regionZoneMap = new Map();

  const zoneDistrictMap = new Map();

  //Create a Hashmap of all regions and zones
  gsppFiltersData[0]?.retailers[0].regions.forEach((region) => {
    regionZoneMap.set(
      region.regionName,
      region.zones.map((z) => z.zoneName)
    );
  });

  // Create a Hashmap of all zones and districts
  gsppFiltersData[0]?.retailers[0].regions.forEach((region) => {
    // Iterate over each zone in the region
    region.zones.forEach((zone) => {
      // Set the districts for the current zone in the map
      zoneDistrictMap.set(zone.zoneName, zone.districts);
    });
  });
  useEffect(() => {
    setSelectedProgramValue(regionValues[0]?.value);
  }, [regionValues]);
  useEffect(() => {
    if (selectedProgramValue && !isApplyingFilters.current) {
      const products = productValues.filter(
        (product) => product?.programName === selectedProgramValue
      );

      if (products && products.length > 0) {
        setSelectedProductValue(products[0]?.value);
        setProductOptions(products);
      } else {
        setSelectedProductValue({});
        setProductOptions([]);
      }
    }
  }, [productValues, selectedProgramValue]);

  useEffect(() => {
    if (selectedProductValue && !isApplyingFilters.current) {
      const product = productValues.find(
        ({ productName, productId }) =>
          productName === selectedProductValue &&
          productId !== constants.products.PromoAI &&
          productId !== constants.products.PricingAI
      );

      if (product) {
        const sectors =
          product.productId === constants.products.ManufacturingControlTower
            ? product.models[0]?.sectors.sort((a, b) =>
                a.sectorName.localeCompare(b.sectorName)
              )
            : product.models[0]?.sectors || [];

        setSelectedSectorValue(sectors[0]?.sectorName || null);
        setSectorOptions(sectors);
      } else {
        setSelectedSectorValue(null);
        setSectorOptions([]);
      }
      const isGuidedSelling =
        product?.productId === constants.products.GuidedSelling;

      setDisplayModel(isGuidedSelling);
      if (isGuidedSelling) {
        const filteredModels = modelValues.filter(
          (model) => model?.productId === product?.productId
        );
        setModelOptions(filteredModels);
        setSelectedModel(
          filteredModels.find((m) => m.value == selectedModelValue)
        );
      }
    }
  }, [selectedProduct, productValues, selectedProductValue]);

  useEffect(() => {
    if (selectedModelValue && displayModel && !isApplyingFilters.current) {
      const models = modelValues.filter(
        (model) => model?.modelName === selectedModelValue
      );
      setSelectedModel(models[0]);
      const sectors = sectorValues.filter(
        (sector) =>
          sector?.productName === selectedProductValue &&
          sector?.modelName === selectedModelValue
      );
      if (sectors && sectors.length > 0) {
        setSelectedSectorValue(sectors[0]?.sectorName);
        setSectorOptions(sectors);
      } else {
        setSelectedSectorValue(null);
        setSectorOptions([]);
      }
    }
  }, [selectedModelValue, sectorValues, plantValues, plantOptions]);

  useEffect(() => {
    if (selectedSectorValue && !isApplyingFilters.current) {
      const markets = sectorOptions.filter(
        (sector) => sector.sectorName === selectedSectorValue
      );
      if (markets && markets.length > 0 && markets[0]?.markets != null) {
        const sortedMarkets = [...markets[0].markets].sort((a, b) => 
          a.marketName.localeCompare(b.marketName)
        );
        // markets[0].markets.sort((a, b) => a.marketName.localeCompare(b.marketName));
        const filteredMarket = sortedMarkets.find(
          (market) => market.marketName === selectedMarketValue
        );

        if (filteredMarket != null) {
          setSelectedMarketValue(filteredMarket.marketName);
          if (selectedProgramValue !== "Canvas") {
            setSelectedMarket(filteredMarket);
          }
        } else {
          setSelectedMarketValue(sortedMarkets[0]?.marketName);
        }
        setMarketOptions(sortedMarkets);
      } else {
        setMarketOptions([]);
      }
    }
  }, [
    selectedSectorValue,
    sectorOptions,
    setSelectedMarket,
    productValues,
    selectedMarketValue,
    marketValues,
    selectedProgramValue,
  ]);

  useEffect(() => {
    if (
      selectedSectorValue &&
      selectedProgramValue === "Manufacturing" &&
      !isApplyingFilters.current
    ) {
      const plants = plantValues.filter(
        (plant) => plant.sectorName === selectedSectorValue
      );
      if (plants && plants.length > 0) {
        setSelectedPlant(plants[0]);
        setPlantOptions(plants);
      } else {
        setSelectedPlant({});
        setPlantOptions([]);
      }
    }
  }, [
    selectedSectorValue,
    sectorOptions,
    setSelectedPlant,
    productValues,
    selectedPlantValue,
    plantValues,
    selectedProgramValue,
  ]);

  useEffect(() => {
    if (selectedMarketValue && displayModel && !isApplyingFilters.current) {
      const markets = gsppFiltersData.filter(
        (market) =>
          market?.marketName?.toUpperCase() ===
          selectedMarketValue?.toString().toUpperCase()
      )[0];
      if (markets) {
        setModelMarket(markets);
        const retailersDropdown = markets?.retailers.map((retailer) => ({
          label: retailer?.retailerName,
          value: retailer?.retailerName,
        }));
        setSelectedRetailer(retailersDropdown[0]);
        setSelectedRetailerValue(retailersDropdown[0]?.value);
        setRetailerOptions(retailersDropdown);
      }
    }
    if(selectedProduct?.productId === constants.products.ManufacturingControlTower){
      setSelectedMarket(null);
    }
  }, [selectedMarketValue, gsppFiltersData, marketValues]);

  useEffect(() => {
    if (
      selectedMarketValue &&
      selectedProductValue === "Retail 360" &&
      !isApplyingFilters.current
    ) {
      const filteredMarkets = marketValues.filter(
        (market) =>
          market?.marketName?.toUpperCase() ===
            selectedMarketValue?.toString().toUpperCase() &&
          market?.customers?.length > 0
      );
      if (filteredMarkets && filteredMarkets.length > 0) {
        const customers = filteredMarkets[0].customers;
        const transformedCustomers = customers.map((customer) => ({
          id: customer?.customerId,
          label: customer?.customerName,
          value: customer?.customerName,
        }));
        const defaultCustomer = transformedCustomers.find((customer) =>
          customer.label.startsWith("WALMART")
        );
        setDefaultCustomerValue(defaultCustomer);
        setSelectedCustomerValue(
          defaultCustomer ||
            (transformedCustomers.length > 0 ? transformedCustomers[0] : {})
        );
        setCustomerOptions(transformedCustomers);
      } else {
        setSelectedCustomerValue({});
        setCustomerOptions([]);
      }
    }
  }, [selectedMarketValue, selectedProductValue, marketValues]);

  useEffect(() => {
    if (selectedRetailerValue && !isApplyingFilters.current) {
      if (modelMarket && Object.keys(modelMarket).length !== 0) {
        const getRegionsDropdown = () => {
          const retailer = modelMarket?.retailers.find(
            (r) => r?.retailerName === selectedRetailerValue
          );
          if (retailer) {
            return retailer.regions.map((region) => ({
              label: region.regionName,
              value: region.regionName,
            }));
          }
          return [];
        };
        const regionsDropDown = getRegionsDropdown();
        setSelectedPPRegion(regionsDropDown[0]);
        setSelectedRegionValue(regionsDropDown[0]?.value);
        setRegionOptions(regionsDropDown);
      }
    }
  }, [selectedRetailerValue]);

  useEffect(() => {
    if (selectedRegionValue) {
      if (modelMarket && Object.keys(modelMarket).length !== 0) {
        const getZonesDropdown = () => {
          const retailer = modelMarket.retailers.find(
            (r) => r.retailerName === selectedRetailerValue
          );
          if (retailer) {
            const region = retailer.regions.find(
              (r) => r.regionName === selectedRegionValue
            );
            if (region) {
              return region.zones.map((zone) => ({
                label: zone.zoneName,
                value: zone.zoneName,
              }));
            }
          }
          return [];
        };

        let changedZone = selectedRegionValue;
        for (const [key, value] of regionZoneMap.entries()) {
          if (value.includes(selectedZoneValue)) changedZone = key;
        }
        const zonesDropDown = getZonesDropdown();

        if (
          JSON.stringify(matchingZoneValue.current) === undefined ||
          changedZone != selectedRegionValue
        ) {
          setSelectedZoneValue(zonesDropDown[0]?.value);
          setSelectedZone(zonesDropDown[0]);
        } else {
          setSelectedZoneValue(matchingZoneValue.current);
          setSelectedZone({
            label: matchingZoneValue.current,
            value: matchingZoneValue.current,
          });
        }

        setZoneOptions(zonesDropDown);
      }
    }
  }, [selectedRegionValue]);

  useEffect(() => {
    if (selectedZoneValue) {
      if (modelMarket && Object.keys(modelMarket).length !== 0) {
        const getDistrictsDropdown = () => {
          const retailer = modelMarket.retailers.find(
            (r) => r.retailerName === selectedRetailerValue
          );
          if (retailer) {
            const region = retailer.regions.find(
              (r) => r.regionName === selectedRegionValue
            );
            if (region) {
              const zone = region.zones.find(
                (z) => z.zoneName === selectedZoneValue
              );
              // Start of code if region is all and zone is selected

              let changedRegion = selectedRegionValue;
              for (const [key, value] of regionZoneMap.entries()) {
                if (
                  selectedZoneValue == "All" &&
                  value.includes(selectedZoneValue)
                ) {
                  if (selectedRegionValue != "All") break;
                  changedRegion = key;
                  break;
                } else if (value.includes(selectedZoneValue)) {
                  changedRegion = key;
                }
              }

              setSelectedRegionValue(changedRegion);
              setSelectedPPRegion({
                label: changedRegion,
                value: changedRegion,
              });
              matchingZoneValue.current = selectedZoneValue;
              setSelectedZoneValue(matchingZoneValue.current);

              // End of logic

              if (zone) {
                const tempDistricts = zone.districts.filter(
                  (el) => el !== null
                );

                const filteredDistrictsSet = new Set(
                  tempDistricts.filter((district) => district !== "All")
                );

                const filteredDistricts =
                  Array.from(filteredDistrictsSet).sort();

                const districts = [
                  { label: "All", value: "All" },
                  ...filteredDistricts.map((district) => ({
                    label: district,
                    value: district,
                  })),
                ];

                return districts;
              }
            }
          }
          return [];
        };
        const districtsDropDown = getDistrictsDropdown();

        let changedDistrict = selectedZoneValue;
        for (const [key, value] of zoneDistrictMap.entries()) {
          if (value.includes(selectedDistrictValue)) changedDistrict = key;
        }

        if (
          matchingDistrictValue.current === undefined ||
          changedDistrict != selectedZoneValue
        ) {
          setSelectedDistrict(districtsDropDown[0]);
          setSelectedDistrictValue(districtsDropDown[0]?.value);
        } else {
          setSelectedDistrict({
            label: matchingDistrictValue.current,
            value: matchingDistrictValue.current,
          });
          setSelectedDistrictValue(matchingDistrictValue.current);
        }

        setDistrictOptions([...districtsDropDown]);
      }
    }
  }, [selectedZoneValue]);

  useEffect(() => {
    let correspondingZone = selectedZoneValue;
    for (const [key, value] of zoneDistrictMap.entries()) {
      if (
        isInitialMountDistrict.current &&
        value.includes(selectedDistrictValue)
      ) {
        correspondingZone = key;
        isInitialMountDistrict.current = false;
        break;
      } else if (value.includes(selectedDistrictValue)) {
        if (
          isInitialMountDistrict.current == false &&
          selectedZoneValue != "All"
        ) {
          correspondingZone = selectedZoneValue;
        } else {
          correspondingZone = key;
        }
      }
    }
    setSelectedZoneValue(correspondingZone);
    setSelectedZone({ label: correspondingZone, value: correspondingZone });
    matchingDistrictValue.current = selectedDistrictValue;
    setSelectedDistrictValue(matchingDistrictValue.current);
  }, [selectedDistrictValue]);

  const { dsdFiltersData, getDsdFiltersData } = useDSD();

  useEffect(() => {
    getDsdFiltersData({
      variables: {},
    });
  }, [getDsdFiltersData]);

  useEffect(() => {
    const formatedStoreOptions = getShipmentVisibilityStoreFilterOptions(
      dsdFiltersData?.storeId ?? []
    );
    setStoreOptions(formatedStoreOptions);
  }, [dsdFiltersData, setStoreOptions]);

  const openDrawer = () => {
    const selectedProductObject = productValues?.find(
      (product) => product?.value === selectedProduct?.value
    );
    setSelectedProduct(selectedProductObject);
    setSelectedProductValue(selectedProduct?.value);
    setState({ ...state, bottom: true });

    const sectors = sectorValues.filter(
      (sector) => sector?.productName === selectedProductValue
    );

    if (sectors && sectors.length > 0) {
      if (!selectedSectorValue) {
        setSelectedSectorValue(sectors[0]?.sectorName);
      }
      setSectorOptions(
        selectedProductObject?.productId ===
          constants.products.ManufacturingControlTower
          ? sectors.sort((a, b) => a.sectorName.localeCompare(b.sectorName))
          : sectors
      );
      if (selectedModel) {
        const filteredSectors = sectors.filter(
          (sector) => sector?.modelId === selectedModel?.id
        );
        setSectorOptions(filteredSectors);
        setSelectedSectorValue(filteredSectors[0]?.sectorName);
      }
    } else {
      setSelectedSectorValue(null);
      setSectorOptions([]);
    }

    if (selectedMarket?.marketName) {
      setSelectedMarketValue(selectedMarket.marketName);
    }
  };

  const closeDrawer = () => {
    setState({ ...state, bottom: false });
  };

  const handleClearAllFilters = () => {
    setSelectedProgramValue(regionValues[0].value);
    setSelectedSector(null);
    setSelectedMarket(null);
    setSelectedModel(null);
    setDisplayModel(false);
    setSelectedRegionValue("All");
    setSelectedTool(toolAllOptions[0]);
    setSelectedProject(projectAllOptions[0]);
    setSelectedStore(basicOption);
    setPlantOptions([]);
  };

  const handleFilterApply = () => {
    isApplyingFilters.current = true;
    
    const selectedProductObject = productValues.find(
      (product) => product.value === selectedProductValue
    );
    const selectedSectorObj = sectorOptions.find(
      (sector) => sector.sectorName === selectedSectorValue
    );
    
    // Conditionally set marketId to null if program is "RGM"
    const selectedMarketObj = 
      selectedProgramValue === "RGM" 
      ? { marketId: null, marketName: null, applicationNames: [] }
      : marketOptions.find(
          (market) => market.marketName === selectedMarketValue
        ) || { marketId: null, marketName: null, applicationNames: [] };
    
    const selectedPlantObj = plantOptions?.find(
      (plant) => plant.value === selectedPlantValue
    ) || { id: null, label: null, value: null };
    
    setSelectedPlant(selectedPlantObj);
    setSelectedProduct(selectedProductObject);
    setSelectedSector(selectedSectorObj);
    setSelectedMarket(selectedMarketObj);
    setSelectedCustomer(selectedCustomerValue || null);
  
    if (selectedProductValue !== "Canvas") {
      setSelectedMarket(selectedMarketObj);
    }
  
    setSelectedPPRegion({
      label: selectedRegionValue,
      value: selectedRegionValue,
    });
    setSelectedZone({ label: selectedZoneValue, value: selectedZoneValue });
    setSelectedDistrict({
      label: selectedDistrictValue,
      value: selectedDistrictValue,
    });
    
    setSelectedFilters({
      selectedProduct,
      selectedSector,
      selectedMarket,
    });
    
    setTimeout(() => {
      isApplyingFilters.current = false;
    }, 2000);
    
    setState({ ...state, bottom: false });
  };
  

  useEffect(() => {
    setDefaultProgramValue(selectedProgram || regionValues[0]?.value);
  }, [selectedProgram, regionValues]);
  useEffect(() => {
    setDefaultProductValue(selectedProduct || productValues[0]?.value);
  }, [selectedProduct, productValues]);
  useEffect(() => {
    setDefaultSectorValue(selectedSectorValue || sectorValues[0]?.value);
  }, [selectedSectorValue, sectorValues]);
  useEffect(() => {
    setDefaultMarketValue(selectedMarketValue || marketValues[0]?.value);
  }, [selectedMarketValue, marketValues]);

  useEffect(() => {
    setDefaultPlantValue(selectedPlantValue || plantValues[0]?.value);
  }, [selectedPlantValue, plantValues]);

  // Create a variable for the Select component
  const customControls = (
    <>
      <Grid
        container
        direction={isTablet ? "row" : "column"}
        justifyContent="flex-start"
        alignItems={isTablet ? "center" : "left"}
        spacing={1}
      >
        {isTablet ? (
          <>
            <Grid item sx={{ marginTop: "12px" }}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: "400",
                  color: themeColor._D0D0D0,
                }}
              >
                YTD
              </Typography>
            </Grid>
            <Grid item sx={{ marginTop: "12px" }}>
              <CustomSwitch
                checked={isChecked}
                inputProps={{ "aria-label": "ant design" }}
                onChange={handleCheckboxChange}
              />
            </Grid>
          </>
        ) : (
          <Grid container item direction={"row"} spacing={1}>
            <Grid item>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: "400",
                  color: themeColor._D0D0D0,
                }}
              >
                YTD
              </Typography>
            </Grid>
            <Grid item>
              <CustomSwitch
                checked={isChecked}
                inputProps={{ "aria-label": "ant design" }}
                onChange={handleCheckboxChange}
              />
            </Grid>
          </Grid>
        )}
        <Grid item>
          <span className="selectLabel">{"Month"}</span>
          <CustomDatePicker
            id="mobile-month-picker"
            portalId="mobile-month-picker"
            setSelectedStartDate={setSelectedStartDate}
            setStartUTCDate={setStartUTCDate}
            setSelectedEndDate={setSelectedEndDate}
            setEndUTCDate={setEndUTCDate}
            getStartOfMonth={getStartOfMonth}
            getEndOfMonth={getEndOfMonth}
            getCurrentDayOfMonth={getCurrentDayOfMonth}
            product={selectedProduct}
            pickerType="month"
            selectedYear={selectedYear}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            checked={isChecked}
          />
        </Grid>
        <Grid item>
          <span className="selectLabel">{"Year"}</span>
          <CustomDatePicker
            id="mobile-year-picker"
            portalId="mobile-year-picker"
            setSelectedStartDate={setSelectedStartDate}
            setStartUTCDate={setStartUTCDate}
            setSelectedEndDate={setSelectedEndDate}
            setEndUTCDate={setEndUTCDate}
            getStartOfMonth={getStartOfMonth}
            getEndOfMonth={getEndOfMonth}
            getCurrentDayOfMonth={getCurrentDayOfMonth}
            product={selectedProduct}
            pickerType="year"
            minSelectableYear={new Date().getFullYear() - 1}
            maxSelectableYear={new Date().getFullYear()}
            setSelectedYear={setSelectedYear}
            selectedYear={selectedYear}
            setSelectedDate={setSelectedDate}
            checked={isChecked}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      {isTablet ? (
        <Styled.FilterBarContainer
          style={{ gap: "12px" }}
          data-testid="mobile-filter-tablet"
        >
          <Button onClick={openDrawer} className="filter-button">
            <MdFilterListAlt color={themeColor._efefef} fontSize={"24px"} />
            Filters
          </Button>
        </Styled.FilterBarContainer>
      ) : (
        <Button onClick={openDrawer} data-testid="mobile-filter">
          <MdFilterListAlt
            color={mode === "light" ? themeColor._3a3a3a : themeColor._efefef}
            fontSize={"24px"}
          />
        </Button>
      )}
      <Drawer anchor={"bottom"} open={state["bottom"]}>
        <Stack className={`drawer-content ${mode}-background`}>
          <Stack
            sx={{
              background:
                mode === "light"
                  ? `${themeColor._FBFCFE} !important`
                  : `${themeColor._1d1d1d} !important`,
              display: "flex",
              alignItems: "stretch",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "12px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  color:
                    mode === "light"
                      ? `${themeColor._3a3a3a} !important`
                      : `${themeColor._efefef} !important`,
                }}
              >
                Filter
              </Typography>
              <AiOutlineClose
                data-testid="close-drawer"
                style={{
                  color:
                    mode === "light" ? themeColor._3a3a3a : themeColor._D0D0D0,
                  fontSize: "24px",
                  fontWeight: 700,
                }}
                onClick={closeDrawer}
              />
            </Stack>
            <AccordionComponent title="Date" mode={mode}>
              <Stack className="date-accordion-content">{customControls}</Stack>
            </AccordionComponent>
            {/* START OF PROGRAM FILTER */}
            <AccordionComponent title="Program" mode={mode}>
              <RadioGroup
                key="program-options"
                data-testid="program-options"
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedProgramValue}
                name="radio-buttons-group"
                onChange={(e) => {
                  setSelectedProgramValue(e.target.value);
                }}
              >
                {regionValues &&
                  regionValues.length > 0 &&
                  regionValues.map((program) => (
                    <FormControlLabel
                      key={program.value}
                      value={program.value}
                      control={
                        <Radio
                          key={program.value}
                          sx={{
                            color:
                              selectedProgramValue === program?.value
                                ? themeColor._3387DF
                                : themeColor._D0D0D0,
                            "&.Mui-checked": {
                              color: themeColor._3387DF,
                            },
                          }}
                          onChange={() => setSelectedProgram(program)}
                        />
                      }
                      label={program?.label}
                    />
                  ))}
              </RadioGroup>
              {selectedProductValue === "Automation" && isMobile && (
                <AutomationFilters />
              )}
            </AccordionComponent>
            {/* START OF PRODUCT FILTER */}
            {selectedProgramValue !== "Automation" &&
              selectedProgramValue !== "Canvas" && 
              selectedProgramValue !== "GPG" && (
                <AccordionComponent title="Product" mode={mode}>
                  <Grid container>
                    <Grid item xs={12}>
                      <RadioGroup
                        key="product-options"
                        data-testid="product-options"
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={selectedProductValue}
                        name="radio-buttons-group"
                        onChange={(e) => {
                          setSelectedProductValue(e.target.value);
                        }}
                      >
                        {productOptions &&
                          productOptions.length > 0 &&
                          productOptions.map((productValue) => (
                            <FormControlLabel
                              key={productValue.value}
                              value={productValue.value}
                              control={
                                <Radio
                                  key={productValue.value}
                                  sx={{
                                    color:
                                      selectedProductValue ===
                                      productValue?.value
                                        ? themeColor._3387DF
                                        : themeColor._D0D0D0,
                                    "&.Mui-checked": {
                                      color: themeColor._3387DF,
                                    },
                                  }}
                                />
                              }
                              label={productValue?.label}
                            />
                          ))}
                      </RadioGroup>
                    </Grid>
                    <Grid xs={12}>
                      {selectedProductValue === "Shipment Visibility" && (
                        <ShipmentVisibilityFilter
                          selectedStore={selectedStore}
                          setSelectedStore={setSelectedStore}
                          storeOptions={storeOptions}
                        />
                      )}
                    </Grid>
                  </Grid>
                </AccordionComponent>
              )}
            {/* END OF PRODUCT FILTER */}
            {/* START OF MODEL FILTER*/}
            {displayModel && (
              <GSPPFilters
                title="Model"
                options={modelOptions}
                selectedValue={selectedModelValue}
                defaultValue={modelValues[0]?.modelName}
                setSelectedValue={setSelectedModelValue}
                setDefaultValue={setDefaultModelValue}
                keyItem={"modelName"}
                mode={mode}
              />
            )}
            {/* START OF Sector FILTER */}
            {selectedProductValue !== "Automation" &&
              selectedProductValue !== "Canvas" &&
              selectedProgramValue !== "RGM" &&
              selectedProgram?.programId !== constants?.programs?.IBP &&
              selectedProgram?.programId !== constants?.programs?.GPG &&
              sectorOptions.length > 0 && (
                <AccordionComponent title="Sector" mode={mode}>
                  <RadioGroup
                    key="sector-options"
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    data-testid="sector-options"
                    value={selectedSectorValue || defaultSectorValue}
                    onChange={(e) => {
                      setSelectedSectorValue(e.target.value);
                      setDefaultSectorValue(e.target.value);
                    }}
                  >
                    {sectorOptions.map((sectorValue) => (
                      <FormControlLabel
                        key={sectorValue.sectorName}
                        value={sectorValue.sectorName}
                        onChange={() =>
                          setSelectedSectorValue(sectorValue?.sectorName)
                        }
                        control={
                          <Radio
                            key={sectorValue.sectorId}
                            sx={{
                              color:
                                selectedSectorValue === sectorValue?.sectorName
                                  ? themeColor._3387DF
                                  : themeColor._D0D0D0,
                              "&.Mui-checked": {
                                color: themeColor._3387DF,
                              },
                            }}
                          />
                        }
                        label={sectorValue?.sectorName}
                        name={sectorValue?.sectorName}
                      />
                    ))}
                  </RadioGroup>
                </AccordionComponent>
              )}
            {/* END OF Sector FILTER */}
            {/* START OF MARKET FILTER */}
            {selectedProductValue !== "Automation" &&
              selectedProgramValue !== "RGM" &&
              selectedProductValue !== "Canvas" &&
              selectedProgramValue !== "GPG" &&
              marketOptions.length > 0 && (
                <AccordionComponent title="Market" mode={mode}>
                  <RadioGroup
                    key="market-options"
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={selectedMarketValue || defaultMarketValue}
                    onChange={(e) => {
                      setSelectedMarketValue(e.target.value);
                      setDefaultMarketValue(e.target.value);
                    }}
                  >
                    {marketOptions.map((marketValue) => (
                      <FormControlLabel
                        key={marketValue.marketName}
                        value={marketValue?.marketName}
                        onChange={() =>
                          setSelectedMarketValue(marketValue?.marketName)
                        }
                        control={
                          <Radio
                            key={marketValue?.marketId}
                            sx={{
                              color:
                                selectedMarketValue === marketValue?.marketName
                                  ? themeColor._3387DF
                                  : themeColor._D0D0D0,
                              "&.Mui-checked": {
                                color: themeColor._3387DF,
                              },
                            }}
                          />
                        }
                        label={marketValue?.marketName}
                        name={marketValue?.marketName}
                      />
                    ))}
                  </RadioGroup>
                </AccordionComponent>
              )}
            {/* END OF MARKET FILTER */}

            {/* START OF SITE FILTER */}

            {selectedProduct?.productId ===
              constants?.products?.ManufacturingControlTower && plantOptions.length > 0 && (
              <AccordionComponent title="Site" mode={mode}>
                <RadioGroup
                  key="plant-options"
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={selectedPlantValue || defaultPlantValue}
                  onChange={(e) => {
                    setSelectedPlantValue(e.target.value);
                    setDefaultPlantValue(e.target.value);
                  }}
                >
                  {plantOptions?.map((plantValue) => (
                    <FormControlLabel
                      key={plantValue.id}
                      value={plantValue?.value}
                      onChange={() => setSelectedPlantValue(plantValue?.value)}
                      control={
                        <Radio
                          key={plantValue?.id}
                          sx={{
                            color:
                              selectedPlantValue === plantValue?.value
                                ? themeColor._3387DF
                                : themeColor._D0D0D0,
                            "&.Mui-checked": {
                              color: themeColor._3387DF,
                            },
                          }}
                        />
                      }
                      label={plantValue?.value}
                      name={plantValue?.value}
                    />
                  ))}
                </RadioGroup>
              </AccordionComponent>
            )}

            {/* END OF SITE FILTER */}

            {selectedProductValue === "Retail 360" &&
              customerOptions.length > 0 && (
                <AccordionComponent title="Customer" mode={mode}>
                  <RadioGroup
                    key="customer-options"
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={
                      selectedCustomerValue?.value ||
                      defaultCustomerValue?.value
                    }
                    onChange={(e) => {
                      setSelectedCustomerValue(
                        customerOptions.filter(
                          (option) => option?.value === e.target.value
                        )[0]
                      );
                      setDefaultCustomerValue(
                        customerOptions.filter(
                          (option) => option?.value === e.target.value
                        )[0]
                      );
                    }}
                  >
                    {customerOptions.map((customer) => (
                      <FormControlLabel
                        key={customer?.label}
                        value={customer?.value}
                        onChange={() => setSelectedCustomerValue(customer)}
                        control={
                          <Radio
                            key={customer?.value}
                            sx={{
                              color:
                                selectedCustomerValue?.value === customer?.value
                                  ? themeColor._3387DF
                                  : themeColor._D0D0D0,
                              "&.Mui-checked": {
                                color: themeColor._3387DF,
                              },
                            }}
                          />
                        }
                        label={customer?.label}
                        name={customer?.value}
                      />
                    ))}
                  </RadioGroup>
                </AccordionComponent>
              )}
            {selectedProductValue == "Guided Selling" &&
              (selectedModel?.id === constants?.modals?.productPairing ||
                selectedModel?.id === constants?.modals?.shareWise) &&
              retailerOptions.length > 0 && (
                <GSPPFilters
                  title="Retailer"
                  options={retailerOptions}
                  selectedValue={selectedRetailerValue}
                  setSelectedValue={setSelectedRetailerValue}
                  keyItem={"value"}
                  mode={mode}
                />
              )}
            {selectedProductValue == "Guided Selling" &&
              (selectedModel?.id === constants?.modals?.productPairing ||
                selectedModel?.id === constants?.modals?.shareWise) &&
              regionOptions.length > 0 && (
                <GSPPFilters
                  title="Regions"
                  options={regionOptions}
                  selectedValue={selectedRegionValue}
                  setSelectedValue={setSelectedRegionValue}
                  keyItem={"value"}
                  mode={mode}
                />
              )}
            {selectedProductValue == "Guided Selling" &&
              (selectedModel?.id === constants?.modals?.productPairing ||
                selectedModel?.id === constants?.modals?.shareWise) &&
              zoneOptions.length > 0 && (
                <GSPPFilters
                  title="Zones"
                  options={zoneOptions}
                  selectedValue={selectedZoneValue}
                  setSelectedValue={setSelectedZoneValue}
                  keyItem={"value"}
                  mode={mode}
                />
              )}
            {selectedProductValue == "Guided Selling" &&
              (selectedModel?.id === constants?.modals?.productPairing ||
                selectedModel?.id === constants?.modals?.shareWise) &&
              districtOptions.length > 0 && (
                <GSPPFilters
                  title="Districts"
                  options={districtOptions}
                  selectedValue={selectedDistrictValue}
                  setSelectedValue={setSelectedDistrictValue}
                  keyItem={"value"}
                  mode={mode}
                />
              )}
          </Stack>

          <Stack
            sx={{
              display: "flex",
              background:
                mode === "light"
                  ? `${themeColor._FBFCFE} !important`
                  : `${themeColor._1d1d1d} !important`,
              flexDirection: "row",
              justifyContent: "center",
              padding: "20px",
              gap: "16px",
            }}
          >
            <Button onClick={handleClearAllFilters} id="clearAllBtn">
              Clear All
            </Button>
            <Button onClick={handleFilterApply} id="applyFilterBtn">
              Apply Filters
            </Button>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
